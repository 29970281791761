import Chart from "chart.js";
import drawFunction from "../../chart-utils/draw-function";
import chartOptions from "../../chart-utils/chart-options";
import parseChartOptions from "../../chart-utils/parse-chart-options";

export default function barChartHorizontal (el, props) {
  let data = {
    labels: props.labels,
    datasets: props.datasets
  };

  // Chart configuration
  parseChartOptions(Chart, chartOptions());
  Chart.elements.Rectangle.prototype.draw = drawFunction;

  let chart = new Chart(el, {
    type: "horizontalBar",
    data: data,
    options: {
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            // Tick interval is effectively set to 10 in chartOptions, and using a callback here seems to be the only
            // way to override it? We want to ensure intervals are whole numbers.
            callback: function(value) {
              if ( !(value % 1) ) {
                return value
              }
            }
          }
        }],
        yAxes: [{
          ticks: {
            // Truncate the label if longer than 40 chars
            callback: function(value) {
              if (value.length > 40) {
                return value.substr(0, 40) + '...';
              } else {
                return value
              }
            },
          },
          afterFit: function(scaleInstance) {
            scaleInstance.width = 300;
          },
        }]
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            var label = data.datasets[item.datasetIndex].label || '';
            var xLabel = item.xLabel;
            var content = '';

            if (data.datasets.length > 1) {
              content += '<span class="popover-body-label mr-auto">' + label + '</span>';
            }

            if (data.datasets.length > 1) {
              content += '<span class="popover-body-value">' + xLabel + '</span>';
            } else {
              content += '<span class="popover-body-value">' + xLabel + ' ' + label + '</span>';
            }

            return content;
          }
        }
      }
    },
  });
}
