import 'select2'

export default function clientForm(el, props) {
  let $el = $(el)
  let select2ClientTypeName = $el.find('#client_client_type_name')

  select2ClientTypeName.select2({tags: true, allowClear: true, placeholder: 'Choose an option'})

  // An edge case can arise with the population of the client_type_name field in the following circumstance:
  //
  // - User #1 has the edit account view open, with a value selected for client_type_name
  // - User #2 edits the selected client type and changes the name
  // - User #1 reloads the edit account view (either manually or as the result of a validation error)
  // - User #1 sees that client_type_name is now blank
  //
  // A workaround is to select the selected client_type_name when the field is initialized
  select2ClientTypeName.val(props.selected_client_type_name)
  select2ClientTypeName.trigger('change');

  function destroy() {
    select2ClientTypeName.select2('destroy')
  }

  return {
    destroy: destroy
  };
}
