export default function accountForm(el, props) {
  function onRestrictImagesElChange() {
    const restrictImagesEl = $("#restrictImages", el)
    const accountImageDisplayLimitWrapperEl = $("#account-image-display-limit-wrapper", el)

    if (restrictImagesEl.is(":checked")) {
      accountImageDisplayLimitWrapperEl.show()
    } else {
      accountImageDisplayLimitWrapperEl.hide()
    }
  }

  $("#restrictImages", el).on("change", onRestrictImagesElChange)
}
