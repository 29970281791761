import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default function accountLogoUpload (el, props) {
  const imageUrlInput = el.querySelector("#account-logo-url-input")

  const profileImageUploader = Uppy({
    id: 'account-logo-image-uploader',
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.jpg', '.jpeg', '.png']
    }
  })

  profileImageUploader.use(Dashboard, {
    id: 'account-logo-image-dashboard',
    target: '#account-logo-image-dashboard',
    inline: true,
    width: "100%",
    height: 400,
    thumbnailWidth: 280,
  })

  profileImageUploader.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: '/'
  })

  profileImageUploader.on('upload-success', (file, response) => {
    imageUrlInput.value = response.uploadURL
  })

  profileImageUploader.on('upload-error', (file, error, response) => {
    console.log('error with file:', file.id)
    console.log('error message:', error)
  })
}
