import flatpickr from "flatpickr"
import "select2"

export default function quickCoverageItemForm (el, props) {
  let $el = $(el)

  const dateField = el.querySelector('.flatpickr-field')

  let flatpickrInstance = flatpickr(dateField, {altInput: true, altFormat: 'J F Y'})

  // Select 2
  let select2multi = $el.find(".select2-multi")
  select2multi.select2()

  let select2Tags = $el.find(".select2-tags")
  select2Tags.select2({tags: true, allowClear: true, placeholder: 'Choose an option or type to add one'})

  function onBacklinkIncludedChange() {
    let backlinkIncludedSelector = $("select[name='quick_coverage_item[backlink_included]")
    let backlinkFieldsWrapperEl = $("#backlink-fields-wrapper")

    if (backlinkIncludedSelector.val().match(/Yes/)) {
      backlinkFieldsWrapperEl.show()
    } else {
      backlinkFieldsWrapperEl.hide()
    }
  }

  $("select[name='quick_coverage_item[backlink_included]").on("change", onBacklinkIncludedChange)

  function destroy() {
    select2Tags.select2('destroy')
    select2multi.select2('destroy')
    flatpickrInstance.destroy()
  }

  return {
    destroy: destroy
  };
}
