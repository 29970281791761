// Default:

// const colors = {
//   gray: {
//     300: '#E3EBF6',
//     600: '#95AAC9',
//     700: '#6E84A3',
//     800: '#152E4D',
//     900: '#283E59'
//   },
//   primary: {
//     100: '#D2DDEC',
//     300: '#A6C5F7',
//     700: '#2C7BE5',
//   },
//   black: '#12263F',
//   white: '#FFFFFF',
//   transparent: 'transparent',
// };

// Impact Score:

const colors = {
  gray: {
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#6E84A3',
    800: '#152E4D',
    900: '#283E59'
  },
  primary: {
    100: '#AFD1E3', // $impact-score-blue-tint-2
    300: '#87BBD6', // $impact-score-blue-tint-1
    700: '#5FA4C8', // $impact-score-blue
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};

export default colors;
