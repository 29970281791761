import Turbolinks from "turbolinks"

export default function favouriteButton (el, props) {
  let $el = $(el)

  const addFavouriteButton = el.querySelector('#add-favourite')
  const removeFavouriteButton = el.querySelector('#remove-favourite')

  function onAddFavouriteButtonClick(e) {
    e.preventDefault()
    $.ajax({
      url: props.path,
      type: 'POST',
      dataType: 'text',
      data: {
        client_id: props.client_id
      },
      success: function(data) {
        Turbolinks.visit(window.location.toString())
      },
      error: function(data) {
        console.log(data)
      }
    })
  }

  function onRemoveFavouriteButtonClick(e) {
    e.preventDefault()
    $.ajax({
      url: props.path + "/" + props.favourite_id,
      type: 'DELETE',
      dataType: 'text',
      success: function(data) {
        Turbolinks.visit(window.location.toString())
      },
      error: function(data) {
        console.log(data)
      }
    })
  }

  addFavouriteButton.addEventListener("click", onAddFavouriteButtonClick)
  removeFavouriteButton.addEventListener("click", onRemoveFavouriteButtonClick)

  function destroy() {
    addFavouriteButton.removeEventListener("click", onAddFavouriteButtonClick)
    removeFavouriteButton.removeEventListener("click", onRemoveFavouriteButtonClick)
  }

  return {
    destroy: destroy
  };
}

