
export default function removeImage (el, props) {
  let $el = $(el)

  const imageInput = document.querySelector(props.inputId)

  function onRemoveImageLinkClick(e) {
    e.preventDefault()
    imageInput.value = null
    el.text = "Image will be removed on save. Click save to remove the image."
  }

  el.addEventListener("click", onRemoveImageLinkClick)

  function destroy() {
    el.removeEventListener("click", onRemoveImageLinkClick)
  }

  return {
    destroy: destroy
  };
}

