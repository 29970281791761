import flatpickr from "flatpickr"

export default function keyPerformanceIndicatorForm (el, props) {
  let $el = $(el)

  const startDateInput = el.querySelector('#startsAtField')
  const endDateInput = el.querySelector('#endsAtField')

  let flatpickrStartInstance = flatpickr(startDateInput, {altInput: true, altFormat: 'J F Y'})
  let flatpickrEndInstance = flatpickr(endDateInput, {altInput: true, altFormat: 'J F Y'})

  function destroy() {
    flatpickrStartInstance.destroy()
    flatpickrEndInstance.destroy()
  }

  return {
    destroy: destroy
  };
}
